import Helmet from 'react-helmet'
import InstagramFeed from '../../components/react-ig-feed'
import '../../components/react-ig-feed/styles.module.css'

import './gallery.css'

// https://www.npmjs.com/package/react-ig-feed
// https://mohammedraji.github.io/posts/get-instagram-graph-API/

const Gallery = () => {
	return (
		<div id='gallery'>
			<Helmet>
				<title>Toutes les réalisations et créations de l'institut de beauté Bellarosa</title>
				<meta name="description" content="Retrouvez toutes les réalisations et créations de l'institut de beauté Bellarosa situé à Merxheim dans le haut-rhin près de Colmar et Mulhouse" />
			</Helmet>
			<h1>Galerie</h1>
			<InstagramFeed token="IGQWRQdTRDRVU0OThlcTRkRlZA5Y2RGYzFmQS1yV1RKWGZAvOEFrR2tkanlfQ1dZAbnNFTGwwRWtIcWljQXJvaUZAydHBOUnJ2S0l1ZAmJGWkkwN3ZAxbVoyMi1lSm1sZATlDS1RVWEZAIUmczeFpORy0xenp6bUgxUzk3aE0ZD" />
		</div>
	)
}

export default Gallery
